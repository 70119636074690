import PropTypes from 'prop-types';
import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../organisms/Layout/Layout';
import BubbleCtaSection from '../../organisms/BubbleCtaSection/BubbleCtaSection';
import { renderPageSections } from '../renderPageSections';
import useLocalize from '../../../hooks/useLocalize';

const SmbPage = ({
  data: rawData,
  location: { href },
  pageContext: { locale },
}) => {
  // unpublished pages return null data
  if (!rawData || rawData.sanitySmbPage === null) {
    return null;
  }

  const data = useLocalize(rawData, locale);

  const smbPageProps = {
    id: data.sanitySmbPage._id,
    metadata: { ...data.sanitySmbPage._rawRoute, href },
    header: data.sanitySmbPage._rawHeader,
    sections: data.sanitySmbPage._rawSections,
    bubbleCtaSection: data.sanitySmbPage._rawBubbleCtaSection,
  };

  return (
    <Layout
      {...smbPageProps.header}
      {...smbPageProps.metadata}
      sanityId={smbPageProps.id}
      locale={locale}
    >
      {renderPageSections(smbPageProps.sections)}
      {smbPageProps.bubbleCtaSection && (
        <BubbleCtaSection {...smbPageProps.bubbleCtaSection} />
      )}
    </Layout>
  );
};

SmbPage.propTypes = {
  data: PropTypes.shape({ sanitySmbPage: {} }),
  location: PropTypes.shape({ href: PropTypes.string }),
  pageContext: PropTypes.shape({ locale: PropTypes.string }),
};

SmbPage.defaultProps = {
  data: {},
  location: { href: '' },
  pageContext: { locale: '' },
};

export default SmbPage;

export const query = graphql`
  query($slug: String) {
    sanitySmbPage(
      route: { enabled: { eq: true }, slug: { current: { eq: $slug } } }
    ) {
      _id
      _rawRoute(resolveReferences: { maxDepth: 100 })
      _rawHeader(resolveReferences: { maxDepth: 100 })
      _rawSections(resolveReferences: { maxDepth: 100 })
      _rawBubbleCtaSection(resolveReferences: { maxDepth: 100 })
    }
  }
`;
